"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useFirstSupportedViewTemplate = useFirstSupportedViewTemplate;
var lodash_1 = require("lodash");
var react_native_1 = require("react-native");
var hooks_1 = require("@shared/scenes/workflows/hooks");
var constants_1 = require("./constants");
function useFirstSupportedViewTemplate(_a) {
    var workflowTemplateId = _a.workflowTemplateId, skip = _a.skip;
    var _b = (0, hooks_1.useViewTemplates)({
        workflowTemplateId: workflowTemplateId,
        type: 'COLLECTION',
        skip: skip,
    }), viewTemplates = _b.viewTemplates, loading = _b.loading, error = _b.error;
    var viewTemplate = getFirstSupportedViewTemplate(viewTemplates);
    return { viewTemplate: viewTemplate, loading: loading, error: error };
}
function getFirstSupportedViewTemplate(viewTemplates) {
    if (react_native_1.Platform.OS === 'web') {
        return (0, lodash_1.first)(viewTemplates);
    }
    else {
        return (0, lodash_1.first)(viewTemplates.filter(isNativeSupportedViewTemplate));
    }
}
function isNativeSupportedViewTemplate(template) {
    return !(0, lodash_1.isNil)(template.subtype) && !constants_1.UNSUPPORTED_NATIVE_TEMPLATES.includes(template.subtype);
}
