"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RelatedEntityEditField = RelatedEntityEditField;
exports.useOpenRelatedEntity = useOpenRelatedEntity;
var lodash_1 = require("lodash");
var react_1 = __importStar(require("react"));
var react_native_1 = require("react-native");
var components_1 = require("@shared/components");
var RelatedEntityField_1 = require("@shared/data/entity/fields/RelatedEntityField");
var hooks_1 = require("@shared/scenes/channel/hooks");
var hooks_2 = require("@shared/scenes/workflows/hooks");
var ChannelEventHandlers_1 = require("@shared/scenes/workflows/hooks/ChannelEventHandlers");
var getTestId_1 = require("@shared/util/getTestId");
var hooks_3 = require("@shared/util/hooks");
var i18n_1 = require("@shared/util/i18n");
var navigation_1 = require("@shared/util/navigation");
var EditFieldContainer_1 = require("../EditFieldContainer");
var RelatedEntityList_1 = require("./RelatedEntityList");
var RelatedEntityPicker_1 = require("./RelatedEntityPicker");
var RelatedQuantityList_1 = require("./RelatedQuantityList");
function RelatedEntityEditField(_a) {
    var component = _a.component, field = _a.field, updateField = _a.updateField, fields = _a.fields, error = _a.error, _b = _a.allowCreate, allowCreate = _b === void 0 ? true : _b, _c = _a.allowClear, allowClear = _c === void 0 ? true : _c;
    var hasQuantity = component.hasQuantity;
    var containerRef = (0, react_1.useRef)(null);
    var _d = (0, hooks_3.useBoolean)(false), isPickerOpen = _d[0], openPicker = _d[1], closePicker = _d[2];
    var items = (0, react_1.useMemo)(function () { var _a; return (_a = field === null || field === void 0 ? void 0 : field.items) !== null && _a !== void 0 ? _a : []; }, [field]);
    var _e = (0, RelatedEntityField_1.useRelatedEntities)(field, component.workflowTemplateId), entities = _e.entities, workflowEntitiesLoading = _e.loading;
    var _f = (0, react_1.useState)(null), openingEntity = _f[0], setOpeningEntity = _f[1];
    var onChange = (0, react_1.useCallback)(function (updatedEntities) {
        var updatedItems = updatedEntities.map(function (entity) {
            var match = items.find(function (item) { return item.id === entity.id; });
            return match !== null && match !== void 0 ? match : { id: entity.id, quantity: hasQuantity ? 1 : undefined };
        });
        updateField(component.id, new RelatedEntityField_1.RelatedEntityField(updatedItems, updatedEntities));
    }, [component.id, updateField, items, hasQuantity]);
    var updateItemQuantity = (0, react_1.useCallback)(function (id, quantity) {
        var updatedItems = items.map(function (item) {
            return item.id === id ? __assign(__assign({}, item), { quantity: quantity }) : item;
        });
        updateField(component.id, new RelatedEntityField_1.RelatedEntityField(updatedItems, entities));
    }, [component.id, updateField, items, entities]);
    var hasEntities = !(0, lodash_1.isEmpty)(entities) || workflowEntitiesLoading;
    var showEntities = !workflowEntitiesLoading && hasEntities;
    var label = hasQuantity
        ? getLabelWithCountAndType(component, items)
        : getLabelWithCount(component, entities.length);
    var isReadOnly = !!component.readonly;
    var openEntity = useOpenRelatedEntity();
    var showClearButton = hasEntities && !isReadOnly && allowClear;
    var onClear = (0, react_1.useCallback)(function () {
        onChange([]);
    }, [onChange]);
    var onItemPress = (0, react_1.useCallback)(function (entity) {
        if ((0, lodash_1.isNil)(entity)) {
            return;
        }
        setOpeningEntity(entity);
        openEntity(entity);
    }, [openEntity]);
    var onItemRemove = (0, react_1.useCallback)(function (entity) {
        if ((0, lodash_1.isNil)(entity)) {
            return;
        }
        onChange(entities.filter(function (e) { return e.id !== entity.id; }));
    }, [entities, onChange]);
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement(react_native_1.TouchableOpacity, { onPress: isReadOnly ? undefined : openPicker, ref: containerRef, disabled: hasEntities || workflowEntitiesLoading || isReadOnly },
            react_1.default.createElement(EditFieldContainer_1.EditFieldContainer, { component: component, label: label, showLabel: hasEntities, showPlaceholder: !hasEntities, onClear: showClearButton ? onClear : undefined, error: error, testID: !hasEntities ? (0, getTestId_1.getTestId)('add-more-button', component.id) : undefined },
                workflowEntitiesLoading && react_1.default.createElement(components_1.MBLoadingIndicator, { style: { alignItems: 'flex-start' } }),
                showEntities && hasQuantity && (react_1.default.createElement(RelatedQuantityList_1.RelatedQuantityList, { component: component, items: items, entities: entities, openPicker: openPicker, onShowMore: openPicker, updateItemQuantity: updateItemQuantity, onItemPress: isReadOnly ? undefined : onItemPress, onItemRemove: isReadOnly ? undefined : onItemRemove, openingEntity: openingEntity })),
                showEntities && !hasQuantity && (react_1.default.createElement(RelatedEntityList_1.RelatedEntityList, { entities: entities, onShowMore: openPicker, onItemPress: isReadOnly ? undefined : onItemPress, onItemRemove: isReadOnly ? undefined : onItemRemove, openPicker: openPicker, isReadOnly: isReadOnly, component: component, openingEntity: openingEntity })))),
        isPickerOpen && (react_1.default.createElement(RelatedEntityPicker_1.RelatedEntityPicker, { entities: entities, onChange: onChange, onClose: closePicker, containerRef: containerRef, component: component, field: field, fields: fields, canCreateNew: allowCreate }))));
}
function useOpenRelatedEntity() {
    var _this = this;
    var navigator = (0, navigation_1.useNavigator)();
    var getChannel = (0, hooks_1.useGetChannel)();
    var getWorkflowTemplate = (0, hooks_2.useGetWorkflowTemplateLazyQuery)();
    return (0, react_1.useCallback)(function (entity) { return __awaiter(_this, void 0, void 0, function () {
        var channel, workflowTemplate;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, getChannel(entity.channelId)];
                case 1:
                    channel = (_b.sent()).channel;
                    return [4 /*yield*/, getWorkflowTemplate(entity.workflowTemplateId)];
                case 2:
                    workflowTemplate = _b.sent();
                    if (!channel || !workflowTemplate) {
                        return [2 /*return*/];
                    }
                    (0, ChannelEventHandlers_1.openCard)(entity.id, channel.slug, {}, navigator, (_a = workflowTemplate === null || workflowTemplate === void 0 ? void 0 : workflowTemplate.behavior) !== null && _a !== void 0 ? _a : undefined);
                    return [2 /*return*/];
            }
        });
    }); }, [getChannel, getWorkflowTemplate, navigator]);
}
function getLabelWithCountAndType(component, items) {
    var _a;
    var count = items.length;
    var totalQuantity = (0, lodash_1.sum)(items.map(function (item) { var _a; return (_a = item.quantity) !== null && _a !== void 0 ? _a : 0; }));
    var componentLabel = (_a = component.label) !== null && _a !== void 0 ? _a : '';
    return (0, i18n_1.i18n)('card.blocks.cardsInput.quantity.label', {
        label: componentLabel,
        totalCount: totalQuantity,
        count: count,
    });
}
function getLabelWithCount(component, count) {
    if (count > 0) {
        var componentLabel = (0, hooks_2.getComponentLabel)(component);
        return "".concat(componentLabel, " (").concat(count, ")").trim();
    }
    else {
        return undefined;
    }
}
