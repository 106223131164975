"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useCollectionViewTemplate = useCollectionViewTemplate;
var lodash_1 = require("lodash");
var react_1 = require("react");
var hooks_1 = require("@shared/scenes/workflows/hooks");
var useCachedTemplateSlug_1 = require("./useCachedTemplateSlug");
var useFirstSupportedViewTemplate_1 = require("./useFirstSupportedViewTemplate");
function useCollectionViewTemplate(_a) {
    var workflowTemplateId = _a.workflowTemplateId, channelSlug = _a.channelSlug, templateSlug = _a.templateSlug;
    var cachedTemplateSlug = (0, useCachedTemplateSlug_1.useCachedTemplateSlug)(channelSlug, templateSlug);
    var selectedViewTemplateId = templateSlug || cachedTemplateSlug;
    var _b = (0, react_1.useState)(!(0, lodash_1.isNil)(selectedViewTemplateId)), isQueryingSpecificTemplate = _b[0], setIsQueryingSpecificTemplate = _b[1];
    var selected = (0, hooks_1.useViewTemplate)(workflowTemplateId, selectedViewTemplateId);
    var firstSupported = (0, useFirstSupportedViewTemplate_1.useFirstSupportedViewTemplate)({
        workflowTemplateId: workflowTemplateId,
        skip: isQueryingSpecificTemplate,
    });
    (0, react_1.useEffect)(function () {
        // triggers the list query because the selected template was likely deleted
        if ((0, lodash_1.isNil)(selected.viewTemplate) && !selected.loading) {
            setIsQueryingSpecificTemplate(false);
        }
    }, [selected]);
    var viewTemplate = selected.viewTemplate || firstSupported.viewTemplate;
    var loading = selected.loading || firstSupported.loading;
    return { viewTemplate: viewTemplate, loading: loading };
}
