"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.NumberEditField = NumberEditField;
var lodash_1 = require("lodash");
var react_1 = __importStar(require("react"));
var components_1 = require("@shared/components");
var util_1 = require("@shared/components/blocks/lib/inputNumber/util");
var NumberField_1 = require("@shared/data/entity/fields/NumberField");
var ComponentFocus_1 = require("@shared/scenes/cards/form/components/ComponentFocus");
var hooks_1 = require("@shared/scenes/workflows/hooks");
var styles_1 = require("@shared/styles");
var hooks_2 = require("@shared/util/hooks");
var EditFieldContainer_1 = require("./EditFieldContainer");
function NumberEditField(_a) {
    var _b, _c;
    var field = _a.field, component = _a.component, updateField = _a.updateField, error = _a.error, allowClear = _a.allowClear;
    var _d = (0, react_1.useState)((_b = field === null || field === void 0 ? void 0 : field.stringValue) !== null && _b !== void 0 ? _b : ''), input = _d[0], setInput = _d[1];
    var colors = (0, react_1.useContext)(styles_1.ColorThemeContext);
    var _e = (0, hooks_2.useBoolean)(false), isFocused = _e[0], onFocus = _e[1], onBlur = _e[2];
    var _f = (0, ComponentFocus_1.useComponentFocus)(), setComponentFocused = _f.setComponentFocused, setComponentBlurred = _f.setComponentBlurred;
    (0, react_1.useEffect)(function () {
        if ((0, lodash_1.isNil)(field === null || field === void 0 ? void 0 : field.value) && !(0, lodash_1.isEmpty)(input) && !isFocused) {
            setInput('');
        }
    }, [field === null || field === void 0 ? void 0 : field.value, input, isFocused]);
    var handleFocus = (0, react_1.useCallback)(function () {
        onFocus();
        setComponentFocused();
    }, [onFocus, setComponentFocused]);
    var handleBlur = (0, react_1.useCallback)(function () {
        onBlur();
        setComponentBlurred();
    }, [onBlur, setComponentBlurred]);
    var updateNumber = (0, react_1.useCallback)(function (textValue) {
        var numericValue = (0, util_1.parseNumber)(textValue, component.format);
        var isValid = !(0, lodash_1.isNil)(numericValue) && !isNaN(numericValue);
        if (textValue === '.') {
            setInput('0.');
        }
        else if (isValid) {
            setInput(textValue);
        }
        if ((0, lodash_1.isEmpty)(textValue)) {
            updateField(component.id, null);
            return;
        }
        if (!isValid) {
            return;
        }
        updateField(component.id, new NumberField_1.NumberField((0, NumberField_1.getNumberSaveValue)(numericValue, component.format), component.format));
    }, [updateField, component.id, component.format]);
    var onClear = (0, react_1.useCallback)(function () {
        setInput('');
        updateField(component.id, null);
    }, [updateField, component.id, setInput]);
    var hasValue = !(0, lodash_1.isEmpty)(input);
    var showClearButton = hasValue && !component.readonly && allowClear;
    var rawValue = input !== null && input !== void 0 ? input : '';
    var formattedValue = (_c = field === null || field === void 0 ? void 0 : field.displayValue) !== null && _c !== void 0 ? _c : '';
    var value = isFocused ? rawValue : formattedValue;
    return (react_1.default.createElement(EditFieldContainer_1.EditFieldContainer, { component: component, showLabel: hasValue, onClear: showClearButton ? onClear : undefined, error: !isFocused ? error : undefined },
        react_1.default.createElement(components_1.MBTextInput, { noBorder: true, noPadding: true, inputMode: "decimal", keyboardType: "decimal-pad", onBlur: handleBlur, onFocus: handleFocus, value: value, onChangeText: updateNumber, placeholder: (0, hooks_1.getComponentPlaceholder)(component), placeholderColor: colors.foregroundInactive, editable: !component.readonly, style: { paddingVertical: !hasValue ? (0, components_1.grid)(1.25) : undefined } })));
}
