"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBKanbanCard = MBKanbanCard;
exports.useRenderKanbanCard = useRenderKanbanCard;
var react_1 = __importStar(require("react"));
var components_1 = require("@shared/components");
var form_1 = require("@shared/components/blocks/contexts/form");
var card_1 = require("@shared/components/card");
var web_1 = require("@shared/util/hooks/web");
var ChannelEventHandlers_1 = require("../../../workflows/hooks/ChannelEventHandlers");
function MBKanbanCard(_a) {
    var card = _a.card, isDragging = _a.isDragging, cardBlocks = _a.cardBlocks, measure = _a.measure, registerChild = _a.registerChild;
    var onOpenCard = (0, ChannelEventHandlers_1.useChannelEventHandlers)().onOpenCard;
    var sizingRef = (0, react_1.useRef)();
    var _b = (0, web_1.useElementSize)(sizingRef), height = _b[1];
    (0, react_1.useEffect)(function () {
        if (!height) {
            return;
        }
        measure === null || measure === void 0 ? void 0 : measure();
    }, [height, measure]);
    return (react_1.default.createElement("div", { ref: registerChild || null },
        react_1.default.createElement("div", { ref: sizingRef },
            react_1.default.createElement(components_1.MBView, { padding: 1, paddingTop: 0, style: isDragging && { opacity: 0.5 } },
                react_1.default.createElement(form_1.MBCardFormProvider, { card: card, blocks: cardBlocks, cardDefinitionId: card === null || card === void 0 ? void 0 : card.cardDefinitionId },
                    react_1.default.createElement(card_1.MBCard, { card: card, blocks: cardBlocks, onPress: isDragging ? undefined : onOpenCard }))))));
}
function useRenderKanbanCard(cards, cardBlocks) {
    return (0, react_1.useCallback)(function (measure, registerChild, style) {
        if (style === void 0) { style = {}; }
        return function (provided, snapshot, rubric) { return (react_1.default.createElement("div", __assign({ ref: provided.innerRef }, provided.draggableProps, provided.dragHandleProps, { style: __assign(__assign({}, provided.draggableProps.style), style) }),
            react_1.default.createElement(MBKanbanCard, { card: cards[rubric.source.index], isDragging: snapshot.isDragging, cardBlocks: cardBlocks, measure: measure, registerChild: registerChild }))); };
    }, [cards, cardBlocks]);
}
