"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBModalOrMobile = void 0;
// @ts-strict-ignore
var React = __importStar(require("react"));
var react_native_1 = require("react-native");
var react_redux_1 = require("react-redux");
var components_1 = require("@shared/components");
function mapStateToProps(state) {
    return {
        isDesktop: !!state.appState.isDesktop,
    };
}
/*
    For web, we're moving away from having content in different side menus.
    Instead we have modals overlaying the whole content. For desktop, we
    want to show this as popup-like modals and on mobile web, we want to
    show it like it looks in the app. This component wraps its children
    depending on screen width.

    Note: this component is a little nasty since it connects itself to the
    redux store. This is generally an anti-pattern as we want to keep
    UI and business logic separated. Suggestions on how to do this nicer
    are appreciated.
*/
exports.MBModalOrMobile = (0, react_redux_1.connect)(mapStateToProps, null)(function (_a) {
    var title = _a.title, isDesktop = _a.isDesktop, backgroundColor = _a.backgroundColor, children = _a.children, headerOnMobile = _a.headerOnMobile, onClose = _a.onClose, onBack = _a.onBack, webOverflow = _a.webOverflow, desktopModalRightButton = _a.desktopModalRightButton, desktopModalLeftButton = _a.desktopModalLeftButton, desktopFooter = _a.desktopFooter, paddingOnMobile = _a.paddingOnMobile, paddingOnWeb = _a.paddingOnWeb, _b = _a.desktopModalWidth, desktopModalWidth = _b === void 0 ? 480 : _b, _c = _a.desktopMinModalHeight, desktopMinModalHeight = _c === void 0 ? 320 : _c, desktopModalHeight = _a.desktopModalHeight, _d = _a.visible, visible = _d === void 0 ? true : _d, _e = _a.isCloseButtonHidden, isCloseButtonHidden = _e === void 0 ? !!onBack : _e, _f = _a.webOverlayStyle, webOverlayStyle = _f === void 0 ? {} : _f;
    if (react_native_1.Platform.OS !== 'web') {
        throw new Error('MBModalOrMobile can only be used on web');
    }
    if (!isDesktop) {
        var content = (React.createElement(components_1.MBView, { fill: true, padding: paddingOnMobile }, children));
        return (React.createElement(components_1.MBView, { fill: true, style: { backgroundColor: backgroundColor } },
            headerOnMobile,
            webOverflow ? (content) : (React.createElement(react_native_1.ScrollView, { contentContainerStyle: { minHeight: '100%', flex: 1 }, children: content }))));
    }
    return (React.createElement(components_1.MBModal, { visible: visible, title: title, backgroundColor: backgroundColor, onBack: onBack, onClose: onClose, isCloseButtonHidden: isCloseButtonHidden, padding: paddingOnWeb, leftButton: desktopModalLeftButton, rightButton: desktopModalRightButton, webOverflow: webOverflow, webFooter: desktopFooter, webOverlayStyle: webOverlayStyle },
        React.createElement(components_1.MBView, { style: {
                width: desktopModalWidth,
                height: desktopModalHeight,
                minHeight: desktopMinModalHeight,
            } }, children)));
});
exports.MBModalOrMobile.displayName = 'MBModalOrMobile';
