"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useBulkUpdate = useBulkUpdate;
var react_1 = require("react");
var useCreateEntityFields_1 = require("@shared/scenes/entity/create/hooks/useCreateEntityFields");
var useFormErrors_1 = require("@shared/scenes/entity/create/hooks/useFormErrors");
var useEntityFormViewTemplate_1 = require("@shared/scenes/entity/util/useEntityFormViewTemplate");
var useViewComponents_1 = require("@shared/scenes/entity/util/useViewComponents");
var hooks_1 = require("@shared/scenes/workflows/hooks");
function useBulkUpdate(_a) {
    var workflowTemplate = _a.workflowTemplate;
    var _b = (0, react_1.useState)(false), saveAttemptedWithLocalErrors = _b[0], setSaveAttemptedWithLocalErrors = _b[1];
    var _c = (0, react_1.useState)([]), selectedComponents = _c[0], setSelectedComponents = _c[1];
    var viewTemplate = (0, useEntityFormViewTemplate_1.useEntityFormViewTemplate)({
        workflowTemplateId: workflowTemplate.id,
        type: 'EDIT',
    });
    var allComponents = (0, useViewComponents_1.useViewComponents)({
        workflowTemplate: workflowTemplate,
        viewTemplate: viewTemplate,
        includeServerManagedComponents: false,
    });
    var availableComponents = allComponents
        .filter(function (component) {
        return !selectedComponents.some(function (selectedComponent) { return selectedComponent.id === component.id; });
    })
        .filter(function (component) { return (0, hooks_1.isInputComponent)(component); })
        .filter(function (component) { return !component.readonly; });
    var _d = (0, useCreateEntityFields_1.useCreateEntityFields)({
        workflowTemplate: workflowTemplate,
        viewTemplate: viewTemplate,
        shouldUseDefaults: false,
    }), fields = _d.fields, updateField = _d.updateField, isDirty = _d.isDirty;
    var onSelectComponent = function (component) {
        setSaveAttemptedWithLocalErrors(false);
        // TODO will need to handle other update states, like clear
        setSelectedComponents(function (prev) { return __spreadArray(__spreadArray([], prev, true), [__assign(__assign({}, component), { required: true })], false); });
    };
    var onRemoveComponent = function (component) {
        updateField(component.id, null);
        setSelectedComponents(function (prev) { return prev.filter(function (c) { return c.id !== component.id; }); });
    };
    var onPressSave = function () {
        if (!isValid) {
            setSaveAttemptedWithLocalErrors(true);
            return;
        }
        setSaveAttemptedWithLocalErrors(false);
        console.log('mutating');
    };
    var isLoading = false; // TODO from mutation, for button
    var _e = (0, useFormErrors_1.useFormErrors)({
        // apiErrors: update.errors, // TODO, from mutation
        fields: fields,
        components: selectedComponents,
        includeRequiredErrors: saveAttemptedWithLocalErrors, // TODO maybe handle separately since depends on field state (set, clear, etc)
    }), errors = _e.errors, isValid = _e.isValid;
    (0, react_1.useEffect)(function () {
        if (isValid) {
            setSaveAttemptedWithLocalErrors(false);
        }
    }, [isValid]);
    return {
        selectedComponents: selectedComponents,
        availableComponents: availableComponents,
        onSelectComponent: onSelectComponent,
        onRemoveComponent: onRemoveComponent,
        fields: fields,
        errors: errors,
        isValid: isValid,
        updateField: updateField,
        onPressSave: onPressSave,
        isLoading: isLoading,
        isDirty: isDirty,
    };
}
