"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NumberField = void 0;
exports.getNumberSaveValue = getNumberSaveValue;
var lodash_1 = require("lodash");
var util_1 = require("@shared/components/blocks/lib/inputNumber/util");
/*
Number formatting rules.

There are 3 formats: Basic, Currency, Percent. And 3 states: input, saved value, display.

- Basic: no difference between input, saved value, and display. All can be float. 1.23 = 1.23, 123 = 123, 123.00 = 123.00
- Currency:
  - Input can be float. If no decimal is provided, .00 is assumed.
  - Saved value is always an integer (cents). 1.23 = 123, 123 = 12300, 123.00 = 12300
  - Display is formatted currency. This looks like input, but always with a decimal and prepended with a dollar sign.
- Percent:
  - Input can be float.
  - Saved value is a float, but we don't manipulate it. 1.23 = 1.23, 123 = 123, 123.00 = 123.00
  - Display is a float converting decimal to percent, saved value * 100 followed by a %. 1.23 = 123%, 123 = 12300%, 123.00 = 12300%
*/
var NumberField = /** @class */ (function () {
    function NumberField(jsonBlob, format) {
        this.fieldType = 'NumberField';
        this.jsonBlob = jsonBlob;
        this.value = convertToValue(Number(jsonBlob), format);
        this.stringValue = String(this.value);
        this.format = format;
    }
    NumberField.prototype.validate = function () {
        return null;
    };
    NumberField.prototype.isEqual = function (field) {
        return (field instanceof NumberField &&
            (0, lodash_1.isEqual)(this.jsonBlob, field.jsonBlob) &&
            this.format === field.format);
    };
    Object.defineProperty(NumberField.prototype, "displayValue", {
        get: function () {
            return (0, util_1.formatNumber)(this.jsonBlob, this.format);
        },
        enumerable: false,
        configurable: true
    });
    NumberField.prototype.toFieldJson = function () {
        return this.jsonBlob;
    };
    Object.defineProperty(NumberField.prototype, "isEmpty", {
        get: function () {
            return (0, lodash_1.isNil)(this.jsonBlob);
        },
        enumerable: false,
        configurable: true
    });
    return NumberField;
}());
exports.NumberField = NumberField;
function getNumberSaveValue(input, format) {
    // converts input to cents if currency
    if (format === 'CURRENCY') {
        return input * 100;
    }
    return input;
}
function convertToValue(number, mode) {
    // determines decimal places based on whether they exist already or not
    if (mode === 'CURRENCY') {
        var digits = Math.round(number / 100) === number / 100 ? 0 : 2;
        return (0, lodash_1.round)(number / 100, digits);
    }
    return number;
}
