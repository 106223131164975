"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useEntityWidget = useEntityWidget;
var client_1 = require("@apollo/client");
var lodash_1 = require("lodash");
var react_1 = require("react");
var react_native_1 = require("react-native");
var api_1 = require("@shared/api");
var CardsEntityService_1 = require("@shared/data/cards/CardsEntityService");
var hooks_1 = require("@shared/scenes/workflows/hooks");
var navigation_1 = require("@shared/util/navigation");
var omitTypeName_1 = require("@shared/util/omitTypeName");
function mergeFilters(collectionCriteria) {
    var _a, _b;
    var mergedFilters = [];
    (_a = collectionCriteria === null || collectionCriteria === void 0 ? void 0 : collectionCriteria.filterByComponents) === null || _a === void 0 ? void 0 : _a.forEach(function (filter) {
        var _a, _b, _c, _d;
        var cleanFilter = (0, omitTypeName_1.omitTypeName)(filter);
        if (((_b = (_a = filter.value) === null || _a === void 0 ? void 0 : _a.dateTokenIs) === null || _b === void 0 ? void 0 : _b.value) === 'TODAY') {
            cleanFilter = __assign(__assign({}, cleanFilter), { value: __assign(__assign({}, cleanFilter.value), (0, CardsEntityService_1.getTodayDateFilters)({ hasTodayToken: true, convertToDateRange: true })) });
        }
        if (!(0, lodash_1.isNil)((_d = (_c = filter.value) === null || _c === void 0 ? void 0 : _c.dateIs) === null || _d === void 0 ? void 0 : _d.relativeDate)) {
            cleanFilter = __assign(__assign({}, cleanFilter), { value: __assign(__assign({}, cleanFilter.value), (0, CardsEntityService_1.getRelativeDateFilters)({
                    isRelative: true,
                    comparisonOperator: filter.value.dateIs.operator,
                    duration: filter.value.dateIs.relativeDate.duration,
                    convertToDateRange: true,
                })) });
        }
        mergedFilters.push(cleanFilter);
    });
    (_b = collectionCriteria === null || collectionCriteria === void 0 ? void 0 : collectionCriteria.groups) === null || _b === void 0 ? void 0 : _b.forEach(function (group) {
        var _a;
        (_a = group.filterByComponents) === null || _a === void 0 ? void 0 : _a.forEach(function (filter) {
            var _a, _b;
            var _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o;
            var groupIsAlreadyInFilters = (_c = collectionCriteria === null || collectionCriteria === void 0 ? void 0 : collectionCriteria.filterByComponents) === null || _c === void 0 ? void 0 : _c.some(function (existingFilter) { return existingFilter.componentId === filter.componentId; });
            if (groupIsAlreadyInFilters) {
                return;
            }
            var existingFilterIndex = mergedFilters.findIndex(function (existingFilter) { return existingFilter.componentId === filter.componentId; });
            if (existingFilterIndex === -1) {
                var cleanFilter = (0, omitTypeName_1.omitTypeName)(filter);
                mergedFilters.push(cleanFilter);
            }
            else {
                var existingFilter = mergedFilters[existingFilterIndex];
                if (!(0, lodash_1.isNil)((_e = (_d = filter.value) === null || _d === void 0 ? void 0 : _d.containsNumber) === null || _e === void 0 ? void 0 : _e.values)) {
                    if (!(0, lodash_1.isNil)((_f = existingFilter.value.containsNumber) === null || _f === void 0 ? void 0 : _f.values)) {
                        (_g = existingFilter.value.containsNumber) === null || _g === void 0 ? void 0 : (_a = _g.values).push.apply(_a, filter.value.containsNumber.values);
                    }
                    else {
                        existingFilter.value.containsNumber = (0, omitTypeName_1.omitTypeName)(filter.value.containsNumber);
                    }
                }
                if (!(0, lodash_1.isNil)((_j = (_h = filter.value) === null || _h === void 0 ? void 0 : _h.containsString) === null || _j === void 0 ? void 0 : _j.values)) {
                    if (!(0, lodash_1.isNil)((_k = existingFilter.value.containsString) === null || _k === void 0 ? void 0 : _k.values)) {
                        (_l = existingFilter.value.containsString) === null || _l === void 0 ? void 0 : (_b = _l.values).push.apply(_b, filter.value.containsString.values);
                    }
                    else {
                        existingFilter.value.containsString = (0, omitTypeName_1.omitTypeName)(filter.value.containsString);
                    }
                }
                if (!(0, lodash_1.isNil)((_m = filter.value) === null || _m === void 0 ? void 0 : _m.isEmpty) && ((_o = filter.value) === null || _o === void 0 ? void 0 : _o.isEmpty)) {
                    existingFilter.value.isEmpty = { value: true };
                }
            }
        });
    });
    return mergedFilters;
}
function useEntityWidget(entityWidget) {
    var _a, _b, _c;
    var navigator = (0, navigation_1.useNavigator)();
    var _d = (0, react_1.useState)(false), shouldShowRefresh = _d[0], setShouldShowRefresh = _d[1];
    var navigateToViewTemplate = function () {
        var destination = react_native_1.Platform.OS === 'web' ? 'ChannelCardList' : 'Channel';
        navigator.navigate(destination, {
            channelSlug: entityWidget.channelSlug,
            templateSlug: entityWidget.viewTemplateId,
        });
    };
    var _e = (0, hooks_1.useViewTemplate)(entityWidget.workflowTemplateId, entityWidget.viewTemplateId), viewTemplate = _e.viewTemplate, viewTemplateLoading = _e.loading;
    var mergedFilters = (0, lodash_1.isNil)(viewTemplate === null || viewTemplate === void 0 ? void 0 : viewTemplate.collectionCriteria)
        ? []
        : mergeFilters(viewTemplate.collectionCriteria);
    var refresh = function () {
        count.refetch();
        setShouldShowRefresh(false);
    };
    var count = (0, client_1.useQuery)(api_1.Queries.workflow.listWorkflowEntitiesCount, {
        variables: {
            input: {
                workflowTemplateId: entityWidget.workflowTemplateId,
                filterByComponents: mergedFilters,
                // TODO if saved
                // omniSearchQuery
            },
        },
        skip: (0, lodash_1.isNil)(viewTemplate),
        fetchPolicy: 'cache-and-network',
    });
    (0, client_1.useSubscription)(api_1.Subscriptions.workflow.event, {
        onData: function (options) {
            var _a;
            (_a = options.data.data) === null || _a === void 0 ? void 0 : _a.event.entities.forEach(function (entity) {
                if (entity.workflowTemplateId === entityWidget.workflowTemplateId) {
                    setShouldShowRefresh(true);
                }
            });
        },
    });
    return {
        loading: count.loading || viewTemplateLoading,
        count: (_c = (_b = (_a = count.data) === null || _a === void 0 ? void 0 : _a.list) === null || _b === void 0 ? void 0 : _b.totalResults) !== null && _c !== void 0 ? _c : 0,
        shouldShowRefresh: shouldShowRefresh,
        refresh: refresh,
        navigate: navigateToViewTemplate,
    };
}
