"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useCollectionViewData = useCollectionViewData;
var BulkUpdateProvider_1 = require("@shared/components/filter/state/BulkUpdateProvider");
var CardsEntityService_1 = require("@shared/data/cards/CardsEntityService");
var useOpenByEntityId_1 = require("@shared/scenes/cards/collectionView/hooks/useOpenByEntityId");
function useCollectionViewData(_a) {
    var workflowTemplateId = _a.workflowTemplateId, viewTemplateId = _a.viewTemplateId, workspaceId = _a.workspaceId, pageSize = _a.pageSize, baseNavigationPath = _a.baseNavigationPath;
    var cardService = (0, CardsEntityService_1.useCardService)({ workflowTemplateId: workflowTemplateId, viewTemplateId: viewTemplateId, pageSize: pageSize, workspaceId: workspaceId });
    (0, BulkUpdateProvider_1.useBulkUpdateStateUpdate)({
        count: cardService.totalCardCount,
        loading: cardService.isCardsLoading,
    });
    var openEntity = (0, useOpenByEntityId_1.useOpenEntityById)(baseNavigationPath, workflowTemplateId);
    return __assign(__assign({}, cardService), { openEntity: openEntity });
}
