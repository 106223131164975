"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBZoomableImage = MBZoomableImage;
// @ts-strict-ignore
var react_1 = __importStar(require("react"));
var react_zoom_pan_pinch_1 = require("react-zoom-pan-pinch");
var spinner_1 = require("@shared/components/loading/spinner");
var hooks_1 = require("@shared/util/hooks");
var web_1 = require("@shared/util/hooks/web");
var styles_1 = require("./styles");
function InsideZoomableImage(_a) {
    var image = _a.image, containerDims = _a.containerDims;
    var setTransform = (0, react_zoom_pan_pinch_1.useControls)().setTransform;
    var _b = (0, react_1.useState)(true), isLoading = _b[0], setIsLoading = _b[1];
    var _c = (0, hooks_1.useImageSrcAndDimensions)({
        image: image,
        containerWidth: containerDims[0],
        containerHeight: containerDims[1],
    }), src = _c.src, width = _c.width, height = _c.height;
    (0, react_1.useEffect)(function () {
        var x = (containerDims[0] - width) / 2;
        var y = (containerDims[1] - height) / 2;
        setTransform(x, y, 1, 0);
    }, [setTransform, containerDims, width, height]);
    var onImageLoad = function () { return setIsLoading(false); };
    return (react_1.default.createElement(react_zoom_pan_pinch_1.TransformComponent, { wrapperStyle: { width: '100%', height: '100%' } },
        react_1.default.createElement("img", { src: src, style: {
                width: width,
                height: height,
                opacity: isLoading ? 0.5 : 1.0,
            }, alt: image.name, onLoad: onImageLoad }),
        isLoading && (react_1.default.createElement("div", { style: styles_1.styles.loadingContainer },
            react_1.default.createElement(spinner_1.MBLoadingIndicator, { fill: true, center: true, padding: 4 })))));
}
/*
    Simple wrapper around the image in the preview. The reason we split this into
    separate files for native and web is because of the native specific things
    happening in content.native.tsx
*/
function MBZoomableImage(_a) {
    var image = _a.image, shouldHaveHorizontalMargins = _a.shouldHaveHorizontalMargins;
    var containerRef = (0, react_1.useRef)();
    var zoomRef = (0, react_1.useRef)();
    var containerDims = (0, web_1.useElementSize)(containerRef);
    var _b = (0, react_1.useState)(false), isZoomed = _b[0], setIsZoomed = _b[1];
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement("div", { ref: containerRef, style: __assign(__assign({}, styles_1.styles.imageContainer), { left: shouldHaveHorizontalMargins ? 100 : 0, right: shouldHaveHorizontalMargins ? 100 : 0, position: 'absolute' }) },
            react_1.default.createElement(react_zoom_pan_pinch_1.TransformWrapper, { ref: zoomRef, 
                // don't allow panning when not zoomed,
                // so carousel swipe will work properly
                panning: { disabled: !isZoomed }, onZoom: function (ref) {
                    setIsZoomed(ref.state.scale > 1);
                } },
                react_1.default.createElement(InsideZoomableImage, { image: image, containerDims: containerDims })))));
}
